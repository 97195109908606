import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page Not Found" />
    <Hero titleOne="Dead End!" titleTwo={["Head Back ", <Link to="/">Home</Link>]} />
  </Layout>
)

export default NotFoundPage

